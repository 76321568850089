<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号" prop="shouJiHao" >
                <el-input v-model="form.shouJiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员类型" prop="huiYuanLX" >
                <el-input v-model="form.huiYuanLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员等级" prop="huiYuanDJ" >
                <el-input v-model="form.huiYuanDJ" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="累计消费金额" prop="leiJiXiaoFeiJinE" >
                <el-input v-model="form.leiJiXiaoFeiJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="累计消费次数" prop="leiJiXiaoFeiCiShu" >
                <el-input v-model="form.leiJiXiaoFeiCiShu" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="最后消费时间" prop="zuiHouXiaoFeiSJ">
                <el-date-picker v-model="form.zuiHouXiaoFeiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="商品类目" prop="shangPinLeiMu">
                <el-input v-model="form.shangPinLeiMu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/HuiYuanFenXi";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                xingMing: [], // 姓名
                shouJiHao: [], // 手机号
                huiYuanLX: [], // 会员类型
                huiYuanDJ: [], // 会员等级
                leiJiXiaoFeiJinE: [], // 累计消费金额
                leiJiXiaoFeiCiShu: [], // 累计消费次数
                zuiHouXiaoFeiSJ: [], // 最后消费时间
                shangPinLeiMu: [], // 商品类目
            }
            this.titlePrefix = '会员分析';
            this.defaultForm = {
                id: null,
                xingMing: "", // 姓名
                shouJiHao: "", // 手机号
                huiYuanLX: "", // 会员类型
                huiYuanDJ: "", // 会员等级
                leiJiXiaoFeiJinE: "", // 累计消费金额
                leiJiXiaoFeiCiShu: "", // 累计消费次数
                zuiHouXiaoFeiSJ: "", // 最后消费时间
                shangPinLeiMu: "", // 商品类目
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>