<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHao" size="small" />
            </x-search-item>
            <x-search-item label="会员类型" >
                <!--                <el-input v-model="search.kaZhuangTai" size="small" />-->
<!--                <el-select v-model="search.huiYuanLX" placeholder="请选择" size="small">-->
<!--                    <el-option-->
<!--                            v-for="item in hylxList" :key="item.name" :label="item.name" :value="item.name">-->
<!--                    </el-option>-->
<!--                </el-select>-->
                <x-selector-one  v-model="search.huiYuanLX" dictType="T_HUI_YUAN_LX"/>
            </x-search-item>
            <x-search-item label="累计消费金额" textWidth="138px" hasEnd>
                <el-input v-model="search.leiJiXiaoFeiJinEBegin" size="small" />
                <el-input v-model="search.leiJiXiaoFeiJinEEnd" size="small" slot="end"/>
            </x-search-item>
            <x-search-item label="累计消费次数" >
                <el-input v-model="search.leiJiXiaoFeiCiShuBegin" size="small" />
                <el-input v-model="search.leiJiXiaoFeiCiShuEnd" size="small" slot="end" />
            </x-search-item>
            <x-search-item label="最后消费时间" textWidth="200" hasEnd>
                <el-date-picker v-model="search.zuiHouXiaoFeiSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.zuiHouXiaoFeiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div><br><br>

        <div style="margin-left: 30px">
            <div>
                <label>消费金额    </label>
                <el-radio-group v-for="item in jeList" :key="item.name" v-model="leiJiXiaoFeiJinE" @change="je0" size="small">
                    <el-radio-button  :label=item.key style="margin-left: 10px">{{item.name}}</el-radio-button>
                </el-radio-group>

                <br><br>
                <label>累计消费次数</label>
                <el-radio-group v-model="leiJiXiaoFeiCiShu" @change="cs" size="small">
                    <el-radio-button v-for="item in csList" :label=item.key :key="item.name" style="margin-left: 10px">{{item.name}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column  prop="xingMing" label="姓名" />
            <el-table-column  prop="shouJiHao" label="手机号" />
            <el-table-column  prop="huiYuanLX" label="会员类型" >
                <x-dict-show slot-scope="{row}" :code="row.huiYuanLX" dictType="T_HUI_YUAN_LX" />
            </el-table-column>
            <el-table-column  prop="huiYuanDJ" label="会员等级" />
            <el-table-column  prop="leiJiXiaoFeiJinE" label="累计消费金额" />
            <el-table-column  prop="leiJiXiaoFeiCiShu" label="累计消费次数" />
            <el-table-column  prop="zuiHouXiaoFeiSJ" label="最后消费时间" />
<!--            <el-table-column  prop="shangPinLeiMu" label="商品类目" />-->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanFenXi";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/hyfx/HuiYuanFenXiEdit";
    import Detail from "@/view/hygl/hyfx/HuiYuanFenXiDetail";
    import {DICT_TYPE_HUI_YUAN_LX, DICT_TYPE_FENXI_COUNT, DICT_TYPE_FENXI_JINE} from "@/util/constant";

    export default {
        name: "HuiYuanFenXiList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                hylxList:DICT_TYPE_HUI_YUAN_LX,
                jeList:DICT_TYPE_FENXI_JINE,
                csList:DICT_TYPE_FENXI_COUNT,
                search: {
                    xingMing: '',
                    shouJiHao: '',
                    huiYuanLX: '',
                    leiJiXiaoFeiJinEBegin: '',
                    leiJiXiaoFeiJinEEnd: '',
                    leiJiXiaoFeiCiShuBegin: '',
                    leiJiXiaoFeiCiShuEnd: '',
                    zuiHouXiaoFeiSJBegin: '',
                    zuiHouXiaoFeiSJEnd: '',
                },
                leiJiXiaoFeiJinE:'不限金额',
                leiJiXiaoFeiCiShu:'不限次数',
            }
        },
        mounted() {
            // console.log(service.list)
        },
        methods:{
            je0(data){
                console.log(data)
                switch (data) {
                    case "1":
                        this.search.leiJiXiaoFeiJinEBegin=null;
                        this.search.leiJiXiaoFeiJinEEnd=null;
                        this.handleSearch();
                        break;
                    case "2":
                        this.search.leiJiXiaoFeiJinEBegin=0;
                        this.search.leiJiXiaoFeiJinEEnd=100;
                        this.handleSearch();
                        break;
                    case "3":
                        this.search.leiJiXiaoFeiJinEBegin=101;
                        this.search.leiJiXiaoFeiJinEEnd=500;
                        this.handleSearch();
                        break;
                    case "4":
                        this.search.leiJiXiaoFeiJinEBegin=501;
                        this.search.leiJiXiaoFeiJinEEnd=2000;
                        this.handleSearch();
                        break;
                }
                console.log(this.search.leiJiXiaoFeiJinEBegin)
                console.log(this.search.leiJiXiaoFeiJinEEnd)
            },
            cs(data){
                switch (data) {
                    case "1":
                        this.search.leiJiXiaoFeiCiShuBegin=null;
                        this.search.leiJiXiaoFeiCiShuEnd=null;
                        this.handleSearch();
                        break;
                    case "2":
                        this.search.leiJiXiaoFeiCiShuBegin=0;
                        this.search.leiJiXiaoFeiCiShuEnd=3;
                        this.handleSearch();
                        break;
                    case "3":
                        this.search.leiJiXiaoFeiCiShuBegin=4;
                        this.search.leiJiXiaoFeiCiShuEnd=10;
                        this.handleSearch();
                        break;
                    case "4":
                        this.search.leiJiXiaoFeiCiShuBegin=11;
                        this.search.leiJiXiaoFeiCiShuEnd=20;
                        this.handleSearch();
                        break;
                }
            }
        }
    }
</script>

<style scoped>
</style>
