<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="shouJiHao" label="手机号" />
            <x-detail-item prop="huiYuanLX" label="会员类型" />
            <x-detail-item prop="huiYuanDJ" label="会员等级" />
            <x-detail-item prop="leiJiXiaoFeiJinE" label="累计消费金额" />
            <x-detail-item prop="leiJiXiaoFeiCiShu" label="累计消费次数" />
            <x-detail-item prop="zuiHouXiaoFeiSJ" label="最后消费时间" />
            <x-detail-item prop="shangPinLeiMu" label="商品类目" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/HuiYuanFenXi";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                xingMing: "", // 姓名
                shouJiHao: "", // 手机号
                huiYuanLX: "", // 会员类型
                huiYuanDJ: "", // 会员等级
                leiJiXiaoFeiJinE: "", // 累计消费金额
                leiJiXiaoFeiCiShu: "", // 累计消费次数
                zuiHouXiaoFeiSJ: "", // 最后消费时间
                shangPinLeiMu: "", // 商品类目
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>